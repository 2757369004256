import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useProductCardQuery from 'queries/useProductCardQuery';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import Events from '@activebrands/core-web/libs/Events';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import StateButton from 'components/buttons/StateButton';
import ThemeButton from 'components/buttons/ThemeButton';
import StateIcon from 'components/icons/StateIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Overlay = styled('div', {
    width: '100%',
    height: '100%',
    color: 'var(--color-base-black)',
    background: 'var(--color-bg-quickshop-overlay)',
    justifyContent: 'space-between',
    zIndex: 'var(--zindex-middleground)',
    scrollbarWidth: 'none', // Hide scrollbar in firefox

    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },

    [media.min['mobile.lg']]: {
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        top: '1px',
        left: '1px',
        overflowY: 'scroll',
        padding: '12px 0px',
    },
});

const Header = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',

    [media.min['mobile.lg']]: {
        padding: '0px 8px',
    },

    [media.min['mobile.sm']]: {
        padding: '12px',
    },
});

const StateIcons = styled('div', {
    position: 'relative',
});

const CurrentProduct = styled('div', {
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',

    [media.min['mobile.lg']]: {
        padding: '0px 8px',
    },

    [media.min['mobile.sm']]: {
        padding: '0px 12px',
    },
});

const Variations = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: ['12px 0px', null, null, '12px 0px'],
    maxHeight: '300px',
    overflowY: 'scroll',
    marginBottom: '16px',
    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },
    scrollbarWidth: 'none', // Hide scrollbar in firefox
});

const Footer = styled('div', {
    [media.min['mobile.lg']]: {
        padding: '0px 8px',
    },

    [media.min['mobile.sm']]: {
        padding: '12px 12px',
    },
});

const Description = styled('div', {
    width: '100%',
    marginBottom: '12px',
});

const ColorInfo = styled('div', {
    marginLeft: '8px',
    ...textStyles['Misc/14_110_-0175'],
});

const QuickshopOverlay = ({
    $style = {},
    activePrice,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    isOpen,
    name,
    originalPrice,
    setButtonState = () => null,
    setIsOpen,
    uri,
    variations = [],
}) => {
    const path = useSelector(state => state.application.path);
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();
    const [css] = useStyletron();
    const overlayRef = useRef();
    const { infoLabel, infoParagraph } = useProductCardQuery();
    const listener = useEventListener('window');

    const [selectedVariant, setSelectedVariant] = useState(null);

    // Close on click if element isn't button or overlay child.
    useEffect(() => {
        let handler = null;

        if (isOpen && setIsOpen) {
            handler = listener.subscribe('click', e => {
                const overlay = overlayRef.current;
                e.stopPropagation();

                if (overlay && e.target !== overlay && overlay.contains(e.target)) {
                    return;
                }

                setIsOpen(false);
            });
        }

        return () => handler && listener.unsubscribe('click', handler);
    }, [isOpen]);

    const handleClick = async (e, variant) => {
        e.preventDefault();
        setButtonState('loading');
        setSelectedVariant(variant);

        try {
            await dispatch(addBasketItem(variant.id, variant.trackingMeta));
            setButtonState('success');

            notifications.push(AddedToBasket, {
                ...addedToBasketData,
                size: variations.filter(item => item.id === variant.id)?.[0]?.name || '',
            });

            if (addedToBasketAlgoliaData) {
                Events.trigger(SearchEvents.PRODUCT_ADDED, addedToBasketAlgoliaData);
            }
        } catch (error) {
            logger.error(error);
            setButtonState('error');
        } finally {
            setTimeout(() => setButtonState('default'), 2800);
            setTimeout(() => setSelectedVariant(null), 2800);
        }
    };

    return (
        <Overlay $style={$style} ref={overlayRef}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <div>
                    <Header>
                        <Heading as="h3" fontKeys="Secondary/16_130_-02">
                            {fm('Add to cart')}
                        </Heading>
                        <StateIcons>
                            <button
                                className={css({
                                    position: 'absolute',
                                    right: 0,
                                    display: ['flex', null, null, 'none'],
                                })}
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    overlay.close('quickshop');
                                }}
                            >
                                <StateIcon size="16px" state="close" />
                            </button>
                            <button
                                className={css({ display: ['none', null, null, 'flex'] })}
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    setIsOpen(false);
                                }}
                            >
                                <StateIcon size="16px" state="close" />
                            </button>
                        </StateIcons>
                    </Header>

                    {name && activePrice && (
                        <CurrentProduct>
                            <Heading $style={{ marginBottom: '4px' }} fontKeys="Misc/16_100_-02">
                                {name}
                            </Heading>
                            {color && (
                                <Paragraph $style={{ color: 'var(--color-text-subtle)' }} fontKeys="Misc/16_100_-02">
                                    {color}
                                </Paragraph>
                            )}

                            <Price
                                activePrice={activePrice}
                                fontStyling={{ ...textStyles['Misc/16_100_-02'] }}
                                originalPrice={originalPrice}
                            />
                        </CurrentProduct>
                    )}
                    {color && (
                        <ColorInfo>
                            <span style={{ color: 'var(--color-text-quickshop-overlay)' }}>Color: </span>
                            <Paragraph as="span" fontKeys="Misc/14_110_-0175">
                                {color}
                            </Paragraph>
                        </ColorInfo>
                    )}
                    <Variations scrolldirection="horizontal">
                        {variations.map((variant, index) => {
                            let message = '';
                            const noPrice = variant.trackingMeta?.priceAsNumber === undefined;

                            // @todo: get few left from api
                            if (variant.fewLeft) {
                                message = 'Few left';
                            } else if (!variant.inStock || noPrice) {
                                message = 'Out of stock';
                            }

                            const isSelected = variant.id === selectedVariant?.id;
                            const stateIcon = isSelected ? 'loading' : 'default';

                            return (
                                <StateButton
                                    $style={{
                                        textAlign: 'left',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        position: 'relative',
                                        height: 'auto',
                                        borderWidth: '0px',
                                        borderTop: '1px solid var(--color-border-buttons-quickshop-overlay)',
                                        borderBottom:
                                            index === variations.length - 1
                                                ? '1px solid var(--color-border-buttons-quickshop-overlay)'
                                                : 'none',
                                        // Special styles for this button since it does not follow the regular theme
                                        ':hover:not(:disabled):not(.disabled)': {
                                            color: 'var(--color-text-button-inverted)',
                                            background: 'var(--color-bg-quickshop-button)',

                                            '::before': {
                                                position: 'absolute',
                                                right: '36px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                content: '""',
                                            },
                                        },
                                        ':disabled': {
                                            ...textStyles['Misc/14_100_-0175_LT'],
                                        },
                                    }}
                                    disabled={!variant.inStock || noPrice}
                                    fontKeys="Misc/14_110_-0175"
                                    key={variant.name}
                                    state={variant.inStock || noPrice ? stateIcon : null}
                                    theme="default"
                                    type="button"
                                    onClick={
                                        variant.inStock
                                            ? e => {
                                                  handleClick(e, variant);
                                                  setTimeout(() => {
                                                      if (setIsOpen) {
                                                          setIsOpen(false);
                                                      }

                                                      overlay.close('quickshop');
                                                  }, 800);
                                              }
                                            : undefined
                                    }
                                >
                                    {variant.name}
                                    <span
                                        className={css({
                                            position: 'absolute',
                                            margin: '0 auto',
                                            right: '132px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            ...textStyles['Misc/10_100_-00625'],
                                        })}
                                    >
                                        {message}
                                    </span>
                                </StateButton>
                            );
                        })}
                    </Variations>
                </div>
                <Footer>
                    <Description>
                        {infoParagraph && (
                            <div className={css(textStyles['Misc/12_130_-1'])}>
                                <RichText data={infoParagraph} />
                            </div>
                        )}
                    </Description>
                    <ThemeButton
                        $style={{ width: '100%' }}
                        as="a"
                        fontKeys="Misc/16_100_-02"
                        theme="defaultsecondary"
                        href={`${path}${uri}`}
                    >
                        {fm('Go to product')}
                    </ThemeButton>
                </Footer>
            </div>
        </Overlay>
    );
};

QuickshopOverlay.propTypes = {
    $style: PropTypes.object,
    activePrice: PropTypes.string,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    originalPrice: PropTypes.string,
    setButtonState: PropTypes.func,
    setIsOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default QuickshopOverlay;
